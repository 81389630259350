import React, { useEffect } from "react"
import { css } from "@emotion/core"
import LivvySVG from "../svg/livvy.svg"
import "../css/landing.css"

const LoadingScreen = ({ setHasLoaded }) => {
  useEffect(() => {
    const timer = setTimeout(() => {
      setHasLoaded(true);
    }, 4500);
    return () => clearTimeout(timer);
  }, [setHasLoaded]);
  return (
    <div
      className="landing-container"
      css={css`
        position: fixed;
        width: 100vw;
        height: 100vh;
        height: calc(var(--vh, 1vh) * 100);
        background: #f9f5f2;
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 2000;
        cursor: none;
      `}
    >
      <LivvySVG
        className="landing-logo"
        css={css`
          position: absolute;
          max-width: 700px;
          width: 80vw;
          top: 50vh;
          top: calc(var(--vh, 1vh) * 50);
          left: 50vw;
          transform: translate(-50%, -70%);
          z-index: 2000;
          @media screen and (max-width: 960px) {
            max-width: 450px;
          }
        `}
      />
      <div className="landing-rectangle"></div>
    </div>
  )
}

export default LoadingScreen
