import React, { useEffect } from "react"
import LoadingScreen from "../components/LoadingScreen"
import SEO from "../components/Seo"

const Home = ({ data, setCurrentPage, hasLoaded, setHasLoaded }) => {
    useEffect(() => {
        setCurrentPage(null);
    })
    return (
        <>
            <SEO title={' '}/>
            {hasLoaded === false && <LoadingScreen setHasLoaded={setHasLoaded}/>}
        </>
    )
};

export default Home;